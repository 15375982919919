<template>
    <div class="container pd100">
        <van-cell title="课程信息" />
        <div class="cell-sub mb10">
            <div class="cell-sub-p">
                <div class="cell-sub-p-bd">{{ detail.class_name }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">课程名称：</div>
                <div class="cell-sub-p-bd">{{ detail.course_title }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">课程时间：</div>
                <div class="cell-sub-p-bd">{{ detail.class_date }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">授课老师：</div>
                <div class="cell-sub-p-bd">{{ detail.course_teacher.teacher_name }}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">上课费用：</div>
                <div v-if="detail.course_price > 0" class="cell-sub-p-bd">{{ detail.course_costs }}课时 / {{detail.course_price}}元</div>
                <div v-else class="cell-sub-p-bd">{{ detail.course_costs }}课时</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">报名人数：</div>
                <div class="cell-sub-p-bd">{{ detail.reserve_count }} / {{detail.table_count}}</div>
            </div>
            <div class="cell-sub-p">
                <div class="cell-sub-p-hd mr5">所属校区：</div>
                <div class="cell-sub-p-bd">{{ detail.course_studio.studio_name }}</div>
            </div>
        </div>

        <van-cell title="上课学员" class="mb10" is-link icon="passed" :value="`${detail.reserve_count}`" @click="memberClick" />

        <van-cell title="当前等位" class="mb10" is-link icon="warning-o" :value="`${detail.course_class_wait_no}`" @click="waitlistClick" />
        
        <div v-if="detail.class_summary" class="schedule-detail-html">
            <van-cell title="上课内容" />
            <div @click="toPreview($event)" class="ql-editor" v-html="html"></div>
        </div>

        <div class="affix-bar">

            <van-button type="info" v-if="detail.course_class_book_id" block @click="toReplace(detail.course_class_book_id)">我要请假</van-button>

            <van-button class="affix-bar-btn" block :loading="btnLoading" v-if="detail.reserve_count<detail.table_count && !detail.course_class_book_id" type="primary" @click="onSubmit">确认报名</van-button>
            <van-button class="affix-bar-btn" block :loading="btnLoading" v-if="detail.reserve_count>=detail.table_count && !detail.course_class_book_id" type="warning" @click="onSubmit">确认等位</van-button>

        </div>

        <van-popup v-model="booklistShow" position="bottom" round :style="{ height: '80%' }">
            <loadings :loading='bookloading' />
            <van-cell v-for="(sub_item,index) in booklist" :key='index' size="small" :label="sub_item.created_at">
                <template #title>
                    <span class="custom-title" >{{index+1}}. {{sub_item.student_name}}</span>
                </template>
            </van-cell>
            <van-empty v-if="booklist.length == 0 && !loading" description="暂无学员" />
        </van-popup>

        <van-popup v-model="waitlistShow" position="bottom" round :style="{ height: '80%' }">
            <loadings :loading='waitloading' />
            <van-cell v-for="(sub_item,index) in waitlist" :key='index' size="small" :label="sub_item.created_at">
                <template #title>
                    <span class="custom-title" >{{index+1}}. {{sub_item.student_name}}</span>
                </template>
            </van-cell>
            <van-empty v-if="waitlist.length == 0 && !loading" description="暂无等位" />
        </van-popup>

        <!-- <loadings :loading='loading' /> -->
        <!-- <Tabbar v-show='!loading' /> -->
    </div>
</template>

<script>
    import IField from '@/components/Field'
    import { Field, Stepper, ImagePreview,Empty} from 'vant'
    import { isEmpty,isPhone } from '@/common/validate'
    import Loadings from '@/components/loading'
    import {addRandomAndGetPreviewImageUrlsFromRichText} from '@/utils/imgPreview'
    export default {
        name:'apply',
        data() {
            return {
                waitloading:false,
                waitlist:[],
                booklist:[],
                waitlistShow:false,
                loading:false,
                btnLoading:false,
                course_studio: {},
                course_teacher:{},
                count:1,
                countMax:100,
                form:[],
                formList:[],
                html:[],
                richImages:[],
                bookloading:false,
                booklistShow:false,
                book_id:this.$route.query.book_id
            }
        },
        props:{  
            course_class_id:Number,
            detail:Object
        },
        components: {
            [Stepper.name]: Stepper,
            [Field.name]: Field,
            [Empty.name]: Empty,
            // Tabbar:Tabbar,
            loadings:Loadings,
            IField,
        },
        created () {
            // this.loading = true
            // this.getDetail()
        },
        methods: {
            toReplace(course_class_book_id){
                this.$router.push({path:'/student/schedule/detail',query:{book_id:course_class_book_id}})
            },
            memberClick(){
                this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'appointment_course_booklist'}).then(res=>{})
                this.bookloading=true
                this.$api.schedule_booklist({ course_class_id:this.detail.course_class_id }).then(res => {
                    this.booklist = res.data
                    this.bookloading = false;
                })
                this.booklistShow = true;
            },
            waitlistClick(){
                this.$api.click_index({event_id:this.detail.course_class_id,studio_id:this.detail.course_studio.studio_id,event_type:'appointment_course_waitlist'}).then(res=>{})
                this.waitloading=true
                this.$api.schedule_waitlist({ course_class_id:this.detail.course_class_id }).then(res => {
                    this.waitlist = res.data
                    this.waitloading = false;
                })
                this.waitlistShow = true;
            },
            onSubmit(){
                this.btnLoading = true
                let formArr = []
                for(let i=0; i<this.formList.length; i++){
                    if(this.formValidate(this.formList[i],i)){
                        formArr.push(this.formList[i].map(item => ({ [item.filedDataName]: item.value })))
                    }else{
                        this.btnLoading = false
                        return false
                    }
                }
                let student = []
                formArr.forEach(item=>{
                    student.push({student_name:item[0].student_name,student_cellphone:item[1].student_cellphone})
                    //student.push({student_name:item[0].student_name,student_cellphone:item[1].student_cellphone,book_remark:item[2].book_remark})
                })
                let obj = {
                    course_class_id:this.course_class_id,
                    student,
                }
                if(this.book_id){
                    obj.replace_book_id = this.book_id
                }
                this.$api.custom_join_courses(obj)
                .then(res=>{
                    this.$toast(`报名成功。`)
                    // Toast.success('报名成功。');
                    this.$parent.$parent.showApply=false;
                    this.$parent.$parent.getCourses();
                    this.btnLoading = false
                    // this.$router.push({path:'/submitSuccess',query:{btnType:1}})
                })
                .catch(err=>{
                    this.btnLoading = false
                })
                this.btnLoading = false
            },
            formValidate(form,ind) {
                for (let index = 0; index < form.length; index++) {
                    if (form[index].required && isEmpty(form[index].value)) {
                        this.$toast(`请输入报名人(${ind+1})的${form[index].filedName}`)
                        this.btnLoading = false
                        return false
                    }
                    if(form[index].filedDataName == 'student_cellphone' && !isPhone(form[index].value)){
                        this.$toast(`报名人(${ind+1})的手机号填写不正确~`)
                        this.btnLoading = false
                    return false
                    }
                }
                this.btnLoading = false
                return true
            },
            onFieldChange({ index, value, parentIndex }) {
                this.formList[parentIndex][index].value = value
            },
            handleStepper(e){
                this.handleChange(this.count)
            },
        },
    }
</script>

<style lang="less" scoped>
  .cell-sub{
    background: #fff;
    padding: 10px 15px;
  }

  .cell-sub-p{
    display: flex;
    margin-bottom: 6px;
    &-hd{
        color: #333;
    }
    &-bd{
        color: #666;
    }
  }
</style>
<style lang="less">
.appointment_schedule_from{
    .i-field-label-align{
        width: 75px;
        text-align-last: inherit;
    }
}
.schedule-detail-html{
    margin-top: 10px;
    img{
        max-width: 100%;
    }
}
</style>